:root {
  --darkBlue: #001229;
  --primaryBlue: #006fff;
  --gradient1: linear-gradient(0deg, #f8f9fb, #f8f9fb),
    linear-gradient(0deg, #f1f9f5, #f1f9f5), #f1f9f5;
  --pageHeight: calc(100vh - 45px);

  --betsHeaderText: rgba(0, 18, 41, 1);
  --betsHeaderBg1: rgba(250, 229, 209, 1);
  --betsHeaderBg2: rgba(228, 233, 248, 1);
  --betsHeaderBg3: rgba(244, 220, 255, 1);
  --elementText: rgba(58, 57, 87, 1);
  --elementText2: rgba(0, 18, 41, 0.8);
  --elementHighlitedText: rgba(82, 95, 115, 1);
  --elementHighlightedBG1: rgba(255, 248, 241, 1);
  --elementHighlightedBG2: rgba(248, 249, 252, 1);
  --parkBtnColor: rgba(255, 141, 33, 1);
  --sendRsBtnColor: rgba(193, 101, 237, 1);
  --leagueElementBg: #eef2fd;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  line-height: 100%;
  /* transition: all 0.2s linear; */
}

@font-face {
  font-family: "GillSans-Light"; /*Can be any text*/
  src: local("GillSans-Light"),
    url("./assets/fonts/GillSans-Light.ttf") format("ttf");
}

.transition {
  transition: all 0.2s linear;
}

.highlight-value {
  background-color: #fff4bc;
  color: #3c4a5c;
}

.yello-strip {
  background-color: #feea79;
  color: #3c4a5c;
  font-weight: 600 !important;
}

.bookmakers-container {
  width: 100%;
}

.bookmaker-icon-betting {
  width: 45px;
  margin-right: 5px;
  float: left;
  cursor: pointer;
}

.bookmaker-icon-small {
  width: 30px;
  margin-right: 5px;
  float: left;
  cursor: pointer;
}

.spinner {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 90vh;
  margin-left: calc(50% - 29px);
  z-index: 1000;
}
